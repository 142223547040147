<template>
    <div class="resumeStore">
        <headLine msg='面试详情'></headLine>  
        <div class="headTop">
            <span>选择班级：</span>
            <el-cascader
                clearable
                :options="options"
                :props='props'
                v-model="classIdList"
                @change="choseClass"
                placeholder="请选择班级">
            </el-cascader>
            <commonExport :exportUrl='exportUrl'></commonExport>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
           
            <el-table-column
            prop="userName"
             :show-overflow-tooltip="true"
            label="姓名">
            </el-table-column>
            <el-table-column
            prop="deliverNum"
            sortable
            :show-overflow-tooltip="true"
            label="投递次数">
            </el-table-column>
            <el-table-column
            prop="invitationNum"
             :show-overflow-tooltip="true"
             sortable
            label="被邀约次数">
            </el-table-column>   
            <el-table-column
            prop="unInvitationNum"
            sortable
            :show-overflow-tooltip="true"
            label="未赴约次数">
            </el-table-column> 
            <el-table-column
            sortable
            prop="interviewNum"
             :show-overflow-tooltip="true"
            label="面试次数">
            </el-table-column> 
             <el-table-column
             sortable
            prop="passNum"
             :show-overflow-tooltip="true"
            label="面试通过次数">
            </el-table-column>         
        </el-table>  
    </div>
</template>
<script>
export default {
    data () {
        return {
            tableData:[],
            options:[],
            classIdList:null,
            props:{
               value:"id",
               label:'name',
               children:'systemOrganizes'
            },
            exportUrl:''
        }
    },
    methods: {
       
        choseClass(){
            if(this.classIdList.length==3){
                let classId=this.classIdList[2]
                this.getInitList(classId)
            }else{
                this.$message({
                    type:"warning",
                    message:"暂无班级"
                })
            }
            
        },    
        getInitList(classId){
            this.exportUrl=`/tea/class/stus/statistics/export?clsId=${classId}`
            let url=`/tea/class/stus/statistics?clsId=${classId}`  
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data
            }) 
        },
        getOrgList(){
            let url=`/tea/org/detail?userId=${sessionStorage.getItem('id')}`  
            this.$htp.get_(url).then((res)=>{
                this.handleOrg(res.data)
            }) 
        },
        handleOrg(data){
            data.forEach(e=>{
                e.systemOrganizes.forEach(v=>{
                    v.systemOrganizes=v.mootClassList
                })
            })
            let classId=data[0].systemOrganizes[0].mootClassList[0].id
            this.classIdList=[data[0].id,data[0].systemOrganizes[0].id,classId]
            this.getInitList(classId)
            this.options=data
        }
    },
    mounted(){
        this.getOrgList()
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/common.scss';
</style>
